<template>
  <form @submit.prevent="handleSearch">
    <input
      v-model="query"
      placeholder="Search..."
      class="search-query"
    >
  </form>
</template>

<script>
export default {
  name: 'WikiSearch',
  data() {
    return {
      query: this.$route.query.q
    }
  },
  methods: {
    handleSearch() {
      this.$emit('search', this.query)
    }
  }
}
</script>

<style lang="scss">
// @import '@/assets/styles/vars.scss';

input.search-query {
  padding: 0.5em 1em;
  border-radius: 20.5px;
  // border: 1px solid $light-grey;
  width: 100%;
  &:focus {
    outline: none;
  }
}
</style>