<template>
  <section class="container">
    <h1>Wiki Search Results</h1>

    <!-- Search Input -->
    <WikiSearch
      class="mb-2"
      @search="search"
    />

    <!-- Results Summary -->
    <h3
      v-if="!wikiSearch.loading && wikiSearch.hits.length"
      class="results-summary"
    >
      Found {{ wikiSearch.count }} {{ wikiSearch.count == 1 ? 'match' : 'matches' }} for <strong>{{ wikiSearch.query }}</strong>.
    </h3>

    <!-- Results -->
    <div
      v-if="wikiSearch.hits.length"
      class="search-results"
    >
      <div
        v-for="hit in wikiSearch.hits"
        :key="hit.objectID"
        class="search-hit"
      >
        <router-link
          :to="`/wiki/${hit.wiki}/${hit.path}`"
          tag="h4"
          class="hit-title"
        >
          {{ hit.title }}
        </router-link>
        <p
          class="hit-excerpt"
          v-html="hit._snippetResult.body.value"
        />
        <p class="hit-metadata">
          Last updated on {{ hit.updated | shortDate }} by {{ hit.updater }}
        </p>
        <hr>
      </div>
    </div>

    <!-- Loading -->
    <h3
      v-else-if="wikiSearch.loading"
      class="loading"
    >
      Loading...
    </h3>

    <!-- No Results -->
    <h3
      v-else
      class="no-results"
    >
      No Results
    </h3>
  </section>
</template>

<script>

// Components
import WikiSearch from '@/components/wiki/wiki-search'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('wiki')

export default {
  name: 'WikiSearchResults',
  components: {
    WikiSearch
  },
  computed: {
    ...mapState([
      'wikiSearch'
    ])
  },
  watch: {
    '$route'() {
      this.loadSearch(this.$route.query.q)
    }
  },
  mounted() {
    this.loadSearch(this.$route.query.q)
  },
  methods: {
    ...mapActions([
      'loadSearch'
    ]),
    search(query) {
      this.$router.push({query: { q: query }})
    }
  }
}
</script>

<style lang="scss">
// @import '@/assets/styles/vars.scss';

h3 {
  font-size: 20px;
  // color: $dark-grey;
  margin: 1em 0;
}

.search-hit {
  .hit-title {
    cursor: pointer;
    text-decoration: underline;
    // color: $sosv-blue;
    &:hover {
      // color: $community-blue;
    }
  }
  .hit-excerpt {
    em {
      font-style: normal;
      font-weight: bold;
      background-color: yellow;
    }
    margin-bottom: 0.5em;
  }
  .hit-metadata {
    font-size: 80%;
    font-style: italic;
    // color: $dark-grey;
    margin-top: 0;
  }
}

</style>